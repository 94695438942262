import { SystemStyleObject } from "@chakra-ui/react";
import { textStyles } from "./textStyles";

const baseStyle: SystemStyleObject = {
  ...textStyles.heading10Medium,
  marginEnd: 3,
  mb: 2,
  fontWeight: 700,
  transitionProperty: "common",
  transitionDuration: "normal",
  opacity: 1,
  _disabled: {
    opacity: 0.4,
  },
};

export const FormLabel = {
  baseStyle,
};
