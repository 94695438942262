import {fonts} from './fonts';
import {fontSizes} from './typography';
import {colors} from './colors';

export const textStyles = {
	stat: {
		fontSize: '96px',
		fontWeight: 400,
		lineHeight: '120px',
		letterSpacing: '0px',
		color: colors.spruce,
		fontFamily: fonts.DMSansBold,
	},
	blockquote: {
		fontSize: '32px',
		fontWeight: 400,
		lineHeight: '40px',
		letterSpacing: '0px',
		color: colors.spruce,
		fontFamily: fonts.DMSansBold,
	},
	heading1Medium: {
		fontSize: fontSizes['8xl'],
		lineHeight: '5.5rem',
		letterSpacing: '-2.4px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading2Medium: {
		fontSize: fontSizes['7xl'],
		lineHeight: '4.5rem',
		letterSpacing: '-1.92px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading3Medium: {
		fontSize: fontSizes['6xl'],
		lineHeight: '4.5rem',
		letterSpacing: '-1.12px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading4Medium: {
		fontSize: fontSizes['5xl'],
		lineHeight: '3.5rem',
		letterSpacing: '-0.96px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading5Medium: {
		fontSize: fontSizes['4xl'],
		lineHeight: '3.5rem',
		letterSpacing: '-0.8px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading6Medium: {
		fontSize: fontSizes['3xl'],
		lineHeight: '2.5rem',
		letterSpacing: '-0.64px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading7Medium: {
		fontSize: fontSizes['2xl'],
		lineHeight: '2.25rem',
		letterSpacing: '-0.48px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading8Medium: {
		fontSize: fontSizes['xl'],
		lineHeight: '2rem',
		letterSpacing: '-0.4px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading9Medium: {
		fontSize: fontSizes['lg'],
		lineHeight: '3.5rem',
		letterSpacing: '-0.32px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	heading10Medium: {
		fontSize: fontSizes['md'],
		lineHeight: '3.5rem',
		letterSpacing: '-0.24px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
	},
	paragraphLGRegular: {
		fontSize: fontSizes['2xl'],
		lineHeight: '2rem',
		letterSpacing: '-0.24px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 400,
	},
	paragraphLG: {
		fontSize: fontSizes['2xl'],
		lineHeight: '36px',
		letterSpacing: '-0.24px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 400,
	},
	paragraphMDRegular: {
		fontSize: fontSizes['xl'],
		// lineHeight: "2rem",
		letterSpacing: '-0.2px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 400,
	},
	paragraphSMRegular: {
		fontSize: fontSizes['md'],
		lineHeight: '1.5rem',
		letterSpacing: '-0.16px',

		fontFamily: fonts.DMSansMedium,
		fontWeight: 400,
	},
	// display1SbLeftWhite: {
	//   fontSize: "4rem",
	//   lineHeight: 1,
	//   letterSpacing: "-2px",
	//   color: colors.navy,
	//   fontFamily: fonts.heading,
	//   fontWeight: 700,
	//   scaledFontSize: 36,
	// },
	// heading1LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["7xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.125em",
	//   letterSpacing: -0.64,
	//   color: colors.arctic,
	// },
	// heading1LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["7xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.125em",
	//   letterSpacing: -0.64,
	//   color: colors.abyss[800],
	// },
	// heading2LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["6xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.142857143em",
	//   letterSpacing: -0.56,
	//   color: colors.arctic,
	// },
	// heading2LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["6xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.142857143em",
	//   letterSpacing: -0.56,
	//   color: colors.abyss[800],
	// },
	// heading3LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["5xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.166666667em",
	//   letterSpacing: -0.48,
	//   color: colors.abyss[800],
	// },
	// heading3LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["5xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.166666667em",
	//   letterSpacing: -0.48,
	//   color: colors.arctic,
	// },
	// heading4CentreAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["4xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.2em",
	//   letterSpacing: -0.4,
	//   color: colors.abyss[800],
	// },
	// heading4LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["4xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.2em",
	//   letterSpacing: -0.4,
	//   color: colors.arctic,
	// },
	// heading4LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["4xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.2em",
	//   letterSpacing: -0.4,
	//   color: colors.abyss[800],
	// },
	// heading5LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["3xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.25em",
	//   letterSpacing: -0.32,
	//   color: colors.arctic,
	// },
	// heading5LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["3xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.25em",
	//   letterSpacing: -0.32,
	//   color: colors.abyss[800],
	// },
	// heading6LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["2xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.333333333em",
	//   letterSpacing: -0.24,
	//   color: colors.arctic,
	// },
	// heading6LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes["2xl"],
	//   fontWeight: 600,
	//   lineHeight: "1.333333333em",
	//   letterSpacing: -0.24,
	//   color: colors.abyss[800],
	// },
	// display8LeftAbyss: {
	//   fontSize: "1rem",
	//   lineHeight: 1.5,
	//   letterSpacing: "-0.16px",
	//   color: colors.abyss[800],
	//   fontFamily: fonts.heading,
	//   fontWeight: 600,
	// },
	// display8LeftArctic: {
	//   fontSize: "1rem",
	//   lineHeight: 1.5,
	//   letterSpacing: "-0.16px",
	//   color: colors.arctic,
	//   fontFamily: fonts.heading,
	//   fontWeight: 600,
	// },
	// tagsBadgesBadgesWhite: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 700,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.arctic,
	// },
	// tagsBadgesBadgesUltramarine: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 700,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.ultramarine[700],
	// },
	// tagsBadgesBadgesAzure: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 700,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.azure[600],
	// },
	// tagsBadgesBadgesSantorini: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 700,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.santorini[500],
	// },
	// tagsBadgesBadgesCapri: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 700,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.capri[500],
	// },

	// heading7LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 600,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.arctic,
	// },
	// heading7LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 600,
	//   lineHeight: "1.2em",
	//   letterSpacing: -0.2,
	//   color: colors.abyss[800],
	// },
	// paragraph1CentreAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 400,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.abyss[800],
	// },
	// paragraph1LeftShadow: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 400,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.shadow[800],
	// },
	// paragraph1LeftGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 400,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.obsidian[200],
	// },
	// paragraph1LeftItalicArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 400,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.arctic,
	// },
	// paragraph1LeftItalicAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.xl,
	//   fontWeight: 400,
	//   lineHeight: "1.6em",
	//   letterSpacing: -0.2,
	//   color: colors.abyss[800],
	// },
	// buttonLeftGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.obsidian[200],
	// },
	// buttonLeftCapri: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.capri[500],
	// },
	// buttonCentreCapri: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.capri[500],
	// },
	// buttonLeftArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.arctic,
	// },
	// buttonCentreSantorini: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.santorini[500],
	// },
	// buttonLeftSantorini: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.santorini[500],
	// },
	// buttonCentreArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.arctic,
	// },
	// buttonCentreGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.obsidian[200][300],
	// },
	// buttonLeftAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// buttonCentreAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 700,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// heading8LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.md,
	//   fontWeight: 600,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.arctic,
	// },
	// heading8CentreAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.md,
	//   fontWeight: 600,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// heading8LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.md,
	//   fontWeight: 600,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// paragraph2LeftItalicGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.obsidian[200],
	// },
	// paragraph2LeftItalicAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// paragraph2LeftItalicArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.arctic,
	// },
	// paragraph2LeftAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.abyss[800],
	// },
	// paragraph2LeftGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.obsidian[200],
	// },
	// paragraph2LeftArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.md,
	//   fontWeight: 400,
	//   lineHeight: "1.5em",
	//   letterSpacing: -0.16,
	//   color: colors.arctic,
	// },
	// tagsBadgesTagsWhite: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 700,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.arctic,
	// },
	// tagsBadgesTagsAzure: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 700,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.azure[600],
	// },
	// tagsBadgesTagsSantorini: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 700,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.santorini[500],
	// },

	// tagsBadgesTagsCapri: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 700,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.capri[500],
	// },
	// tagsBadgesTagsUltramarine: {
	//   fontFamily: fonts.tag,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 700,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.ultramarine[700],
	// },
	// heading9LeftArctic: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 600,
	//   lineHeight: "1.428571429em",
	//   letterSpacing: -0.14,
	//   color: colors.arctic,
	// },
	// heading9LeftGranite: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 600,
	//   lineHeight: "1.428571429em",
	//   letterSpacing: -0.14,
	//   color: colors.obsidian[200],
	// },
	// heading9LeftAbyss: {
	//   fontFamily: fonts.heading,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 600,
	//   lineHeight: "1.428571429em",
	//   letterSpacing: -0.14,
	//   color: colors.abyss[800],
	// },
	// paragraph3LeftItalicGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.obsidian[200],
	// },
	// paragraph3LeftItalicAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.abyss[800],
	// },
	// paragraph3LeftItalicArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   fontStyle: "italic",
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.arctic,
	// },
	// paragraph3LeftAbyss: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.abyss[800],
	// },
	// paragraph3LeftSuccess: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.capri[500],
	// },
	// paragraph3LeftError: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: "coral",
	// },
	// paragraph3LeftArctic: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.arctic,
	// },
	// paragraph3LeftGranite: {
	//   fontFamily: fonts.body,
	//   fontSize: fontSizes.sm,
	//   fontWeight: 400,
	//   lineHeight: "1.714285714em",
	//   letterSpacing: -0.14,
	//   color: colors.obsidian[200],
	// },
};
