/**
 * /* eslint @typescript-eslint/no-unsafe-assignment: 1
 *
 * @format
 */

import {
	ApolloClient,
	createHttpLink,
	InMemoryCache,
	NormalizedCacheObject,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';

let client: ApolloClient<NormalizedCacheObject>;

/**
 * getApolloClient
 */

export function getApolloClient(
	refreshToken = process.env.WORDPRESS_AUTH_REFRESH_TOKEN,
) {
	if (!client) {
		client = _createApolloClient(refreshToken);
	}

	return client;
}

/**
 * createApolloClient
 */
//
export function _createApolloClient(refreshToken?: string) {
	const authLink = setContext((_, {headers}) =>
		// return the headers to the context so httpLink can read them
		({
			headers: {
				...headers,
				authorization: refreshToken ? `Bearer ${refreshToken}` : '',
			},
		}),
	);

	return new ApolloClient({
		link: authLink.concat(httpLink),
		cache: new InMemoryCache(),
	});
}

const httpLink = createHttpLink({
	uri: process.env.NEXT_PUBLIC_WORDPRESS_API_URL,
});
