import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterProps } from "../../classes/filter";
import { RootState } from "../store";

const initialState = new FilterProps();

export const filterSlice = createSlice({
  name: "filterSlice",
  initialState,
  reducers: {
    setFirstFilter: (state, action: PayloadAction<any>) => {
      //Handles the dropdown filter selection - deselection
      const payload = action.payload;

      return {
        ...state,
        firstFilter: payload,
        selectedFilters: [payload]
      }
    },
    pushToSelectedFilters: (state, action: PayloadAction<any>) => {
      //Handles the filter selection - deselection
      return {
        ...state,
        selectedFilters: [action.payload]
      }
    },
  },
});

// destructure actions and reducer from the slice (or you can access as counterSlice.actions)
const { actions, reducer } = filterSlice;

// export individual action creator functions
export const { pushToSelectedFilters, setFirstFilter } = actions;

// Selectors can use the imported `RootState` type
export const selectedFiltersState = (state: RootState) => state.filterSlice;

export default reducer;
