export const fontSizes = {
  xs: "0.75rem", //12px
  sm: "0.875rem", //14px
  md: "1rem", //16px
  lg: "1.125rem", //18px
  xl: "1.25rem", //20px
  "2xl": "1.5rem", //24px
  "3xl": "2rem", // 32px
  "4xl": "2.5rem", //40px
  "5xl": "3rem", //48px
  "6xl": "3.5rem", //56px
  "7xl": "4rem", //64px
  "8xl": "5rem", //80px
};
