export const colors = {
  white: "#fff",
  black: "#000",
  arctic: "#ffffff",
  coral: "#ff4d70",
  navy: "#121D33",
  pine: "#1D4752",
  spruce: "#008292",
  seafoam: "#59D8C9",
  lime: "#D4E157",
  stone: "#F4F5F0",
  honey: "#FFC927",
  maple: "#FF7043",
  plum: "#521D29",
  gray: {
    10: "#F2F3F5",
    25: "#B4B5B8",
    50: "#5A5E66",
    75: "#171D29",
  },
  arcticScale: {
    50: "#fff",
    100: "#fff",
    200: "#fff",
    300: "#fff",
    400: "#fff",
    500: "#fff",
    600: "#fff",
    700: "#fff",
    800: "#fff", // labelled Abyss in designs
    900: "#fff",
  },
  abyss: {
    50: "#E4E6FF",
    100: "#b3b5ff",
    200: "#8183fd",
    300: "#5053fc",
    400: "#2423fb",
    500: "#110ce2",
    600: "#0a08b0",
    700: "#04057e",
    800: "#00024c", // labelled Abyss in designs
    900: "#00011d",
  },
  ultramarine: {
    50: "#e4eaff",
    100: "#b4c1ff",
    200: "#8498fb",
    300: "#546ff8",
    400: "#2645f5",
    500: "#112ddc",
    600: "#0a22ab",
    700: "#04187b", // labelled Ultramarine in designs
    800: "#000f4c",
    900: "#00051e",
  },
  azure: {
    50: "#e4ecff",
    100: "#b4c5ff",
    200: "#849efb",
    300: "#5478f8",
    400: "#2651f5",
    500: "#0e38db",
    600: "#072bab", // labelled Azure in designs
    700: "#021f7b",
    800: "#00134c",
    900: "#00061e",
  },
  santorini: {
    50: "#ddf3ff",
    100: "#b0daff",
    200: "#80bfff",
    300: "#4ea5fb",
    400: "#208cf9",
    500: "#0672df", // labelled Santorini in designs
    600: "#0059af",
    700: "#003f7e",
    800: "#00264e",
    900: "#000e20",
  },
  capri: {
    50: "#dbfefd",
    100: "#b6f6f1",
    200: "#8dede6",
    300: "#64e5dd",
    400: "#3bddd3",
    500: "#23CCC1", // labelled Capri in designs
    600: "#129890",
    700: "#036e67",
    800: "#00423f",
    900: "#001817",
  },
  obsidian: {
    50: "#eff4f6",
    100: "#d5dddf",
    200: "#b8c6cb",
    300: "#9ab1b7",
    400: "#7d9ba4",
    500: "#64818a",
    600: "#4f646b",
    700: "#39474c",
    800: "#222b2d",
    900: "#090f10", // labelled Obsidian in designs
  },
  shadow: {
    50: "#ebf5f5",
    100: "#d7dadb",
    200: "#bfc0c1",
    300: "#a6a8a8",
    400: "#8c8f8f",
    500: "#737576",
    600: "#595b5c",
    700: "#3f4142",
    800: "#242728", // labelled Shadow in designs
    900: "#040f0f",
  },
  granite: {
    50: "#fceff2",
    100: "#ddd7d9",
    200: "#c1bfbf",
    300: "#a6a6a6", // labelled Granite in designs
    400: "#8c8c8c",
    500: "#737373",
    600: "#595959",
    700: "#413f40",
    800: "#292526",
    900: "#16090d",
  },
  lunar: {
    50: "#f2f2f2", // labelled Lunar in designs
    100: "#d9d9d9",
    200: "#bfbfbf",
    300: "#a6a6a6",
    400: "#8c8c8c",
    500: "#737373",
    600: "#595959",
    700: "#404040",
    800: "#262626",
    900: "#0d0d0d",
  },
};
