/** @format */

export class BlogCardsProps {
	blogCards: [
		{
			title: string;
			cardView: {
				title?: string;
				excerpt?: string;
				image?: {sourceUrl: string; altText?: string};
			};
			postType: string;
			projectsLayout: {category: Array<{name: string}>};
			servicesLayout: {category: Array<{name: string}>};
			newsAndInsightsLayout: {category: Array<{name: string}>};
			otherLayout: {};
			slug: string;
			heroImage: {link: string};
			heroText: string;
			heroDescription: string;
			date: string;
		},
	];
	pageInfo: {
		endCursor: string;
		hasNextPage: boolean;
	};

	constructor() {
		(this.blogCards = [
			{
				title: '',
				postType: '',
				projectsLayout: {category: new Array<{name: string}>()},
				servicesLayout: {category: new Array<{name: string}>()},
				newsAndInsightsLayout: {category: new Array<{name: string}>()},
				otherLayout: {},
				date: '',
				heroText: '',
				heroDescription: '',
				heroImage: {link: ''},
				slug: '',
				cardView: {title: '', excerpt: '', image: {sourceUrl: '', altText: ''}},
			},
		]),
			(this.pageInfo = {
				endCursor: '',
				hasNextPage: false,
			});
	}
}
