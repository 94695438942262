/** @format */

import {extendTheme} from '@chakra-ui/react';
//custom theme imports
import {colors} from './colors';
import {textStyles} from './textStyles';
import {fonts} from './fonts';
import {fontSizes} from './typography';
import {Button} from './button';
import {Link} from './link';
import {FormLabel} from './formLabel';
import {Input} from './input';
import {FormError} from './formError';
import {Textarea} from './textarea';
import {layerStyles} from './layerStyles';

// Extending the theme to include custom colors, fonts, etc
export const theme = extendTheme({
	primaryFontColor: {
		lightMode: 'red',
		darkMode: colors.navy,
	},
	styles: {
		global: props => ({
			body: {
				fontFamily: 'body',
				// bg: mode("white", `${colors.navy}`)(props),
				bg: 'white',
				color: colors.navy,
				lineHeight: 'base',
			},
			'.WYSIWYG--default': {
				li: {
					'&:last-child': {
						marginBottom: '1.5rem',
					},
				},
				a: {
					fontWeight: 'bold',
					color: colors.spruce,
					textDecorationColor: colors.ultramarine[500],
					position: 'relative',
					cursor: 'pointer',
					textDecoration: 'none',
					// for link-underline animation
					backgroundRepeat: 'no-repeat',
					backgroundSize: '0% 2px',
					backgroundPosition: '0 99%',
					backgroundImage: `linear-gradient(to right, ${colors.spruce} 100%, transparent 0)`,
					transition: 'background-size .2s ease-in-out',
					_hover: {
						textDecoration: 'none',
						color: colors.spruce,
						backgroundSize: '100% 2px',
					},
					_disabled: {
						color: colors.ultramarine[400],
					},
				},

				p: {
					fontFamily: 'DM Sans',
					fontSize: '20px',
					fontWeight: '400',
					lineHeight: '36px',
					letterSpacing: '-0.23999999463558197px',
					paddingBottom: '32px',
				},
				'.aligncenter': {
					marginLeft: 'auto',
					marginRight: 'auto',
				},
			},
		}),
	},
	components: {
		Heading: {
			sizes: null,
		},
		Button,
		Link,
		FormLabel,
		Input,
		FormError,
		Textarea,
	},
	fonts,
	fontSizes,
	colors,
	space: {
		spacer: '64px',
	},
	sizes: {
		maxWidth: '1200px',
	},
	config: {
		initialColorMode: 'light',
		useSystemColorMode: false,
	},
	textStyles,
	layerStyles,
});

export default theme;
