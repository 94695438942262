import {colors} from './colors';
import {fonts} from './fonts';

export const Button = {
	// 1. Update the base styles
	baseStyle: {
		maxWidth: '162px',
		paddingEnd: '24px',
		paddingStart: '24px',
		borderRadius: '24px',
		fontFamily: fonts.DMSansMedium,
		fontWeight: 500,
		lineHeight: '1.5em',
		letterSpacing: -0.32,
	},
	sizes: {
		md: {
			h: '3em',
			fontSize: 'md',
			px: '24px',
			py: '12px',
		},
	},
	defaultProps: {
		variant: 'solid',
		size: 'md',
		colorScheme: 'capri',
	},

	// 3.Add visual variants
	variants: {
		// We cant set borderRadius because of a bug, for now i have built a custom IconButton component to use https://github.com/chakra-ui/chakra-ui/pull/4265/commits/d8b9b23e2e6584e04e87bfca21c50165b62b37f3
		primary: {
			boxSizing: 'border-box',
			bg: colors.seafoam,
			_disabled: {
				bg: colors.gray[25],
			},
			_hover: {
				color: 'white',
				bg: colors.navy,
				path: {
					fill: 'white',
				},
			},
			_focus: {
				outline: '4px solid #D5D7F6',
				outlineOffset: '0px',
			},
			_active: {
				bg: 'capri.600',
			},
		},

		secondary: {
			bg: colors.navy,
			color: 'white',
			path: {
				fill: 'white',
			},
			_hover: {
				color: colors.navy,
				bg: 'white',
				path: {
					fill: colors.navy,
				},
			},
			_disabled: {
				borderColor: 'colors.navy',
			},
			_focus: {
				color: 'white',
				bg: colors.navy,
				outline: '4px solid #D3F8F4',
				outlineOffset: '0px',
				path: {
					fill: 'white',
				},
			},
			_active: {
				borderColor: 'colors.navy',
			},
		},

		tertiary: {
			bg: 'white',
			color: colors.navy,
			path: {
				fill: colors.navy,
			},
			_hover: {
				color: colors.navy,
				bg: colors.seafoam,
				path: {
					fill: colors.navy,
				},
			},

			_focus: {
				color: colors.navy,
				bg: 'white',
				outline: '4px solid #D3F8F4',
				outlineOffset: '0px',
				path: {
					fill: colors.navy,
				},
			},
			_active: {
				borderColor: 'colors.navy',
			},
			_disabled: {
				borderColor: 'colors.navy',
			},
		},

		transparent: {
			bg: 'transparent',
			border: '2px solid white',
			color: 'white',
			path: {
				fill: 'white',
			},
			_hover: {
				color: colors.navy,
				bg: colors.seafoam,
				border: `2px solid ${colors.seafoam}`,
				path: {
					fill: colors.navy,
				},
			},
			_focus: {
				bg: 'transparent',
				color: 'white',
				path: {
					fill: 'white',
				},
				border: 'none',
				outline: '4px solid #D3F8F4',
				outlineOffset: '0px',
			},
			_active: {
				borderColor: 'colors.navy',
			},
			_disabled: {
				borderColor: 'colors.navy',
			},
		},
		border: {
			width: '100%',
			bg: 'white',
			color: colors.navy,
			border: `2px solid ${colors.navy}`,
			_hover: {
				color: 'white',
				bg: colors.navy,
				path: {
					fill: 'white',
				},
			},
			_focus: {
				bg: 'white',
				color: colors.navy,
				outline: '#D5D7F6 solid 2px',
				outlineOffset: '0px',
				path: {
					fill: colors.navy,
				},
			},
			_active: {
				border: `2px solid ${colors.navy}`,
				path: {
					fill: colors.navy,
				},
				_disabled: {
					borderColor: 'colors.navy',
				},
			},
		},
		icon: {
			bg: 'white',
			_hover: {
				bg: 'lunar.100',
			},
			_focus: {
				bg: 'lunar.200',
			},
			_active: {
				bg: 'lunar.200',
			},
		},
		solid: props => ({
			color: 'abyss.800',
			_hover: {
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.400`,
			},
			_active: {
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.600`,
			},
			_focus: {
				bg:
					props.colorScheme === 'gray'
						? 'gray.100'
						: `${props.colorScheme}.600`,
			},
			_disabled: {
				color: 'granite.300',
			},
		}),
		outline: props => {
			const {colorScheme: c} = props;
			const borderColor = `gray.200`;
			return {
				border: '2px solid',
				borderColor: c === 'gray' ? borderColor : `${c}.500`,
				color: 'abyss.800',
				bg: 'transparent',
				_hover: {
					bg: 'transparent',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.300`,
				},
				_active: {
					bg: 'transparent',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.600`,
				},
				_disabled: {
					color: 'granite.300',
					borderColor: c === 'gray' ? 'gray.100' : `${c}.300`,
				},
				_focus: {
					borderColor: c === 'gray' ? 'gray.100' : `${c}.600`,
				},
			};
		},
		link: props => {
			const {colorScheme: c} = props;
			return {
				color: c === 'abyss' ? 'abyss.800' : `${c}.500`,
				_hover: {
					textDecoration: 'underline',
					_disabled: {
						textDecoration: 'none',
					},
				},
				_active: {
					color: c === 'abyss' ? 'abyss.800' : `${c}.500`,
				},
			};
		},

		letsTalk: {
			bg: colors.navy,
			color: 'white',
			path: {
				stroke: 'white',
			},
			_hover: {
				color: colors.navy,
				bg: 'white',
				path: {
					stroke: colors.navy,
				},
			},
			_disabled: {
				borderColor: 'colors.navy',
			},
			_focus: {
				color: 'white',
				bg: colors.navy,
				outline: '4px solid #D3F8F4',
				outlineOffset: '0px',
				path: {
					stroke: 'white',
				},
			},
			_active: {
				borderColor: 'colors.navy',
			},
		},
	},
};
