import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {DefaultSeo} from 'next-seo';
import {theme} from '../styles/js/theme';
import {ApolloProvider} from '@apollo/client';
import {getApolloClient} from '../lib/api/apollo-client';
import '../styles/scss/globalStyles.scss';
import {Provider} from 'react-redux';
import {store} from '../redux/store';
import '../styles/scss/home-page.css';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ExitPreviewPanel from '../components/molecules/ExitPreviewPanel';

Bugsnag.start({
	apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
});
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function App({Component, pageProps}) {
	return (
		<ErrorBoundary>
			<ApolloProvider client={getApolloClient()}>
				<ChakraProvider theme={theme}>
					{/* <ColorModeScript initialColorMode={theme.config.initialColorMode} /> */}
					<Provider store={store}>
						<DefaultSeo
							title='Bulb Studios | Pioneering Digital Products'
							description='Bulb Studios are a digital product studio creating pioneering digital products and growing digital-first brands.'
						/>
						<div id='mainAppWrapper'>
							<ExitPreviewPanel />
							<Component {...pageProps} />
						</div>
					</Provider>
				</ChakraProvider>
			</ApolloProvider>
		</ErrorBoundary>
	);
}
