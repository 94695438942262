import {Button, HStack} from '@chakra-ui/react';
import {useRouter} from 'next/router';
import React from 'react';

function ExitPreviewPanel() {
	const {isPreview} = useRouter();

	if (!isPreview) return null;

	return (
		<HStack border='1px solid red'>
			<Button variant='primary' as='a' href='/api/exit-preview'>
				Exit Preview
			</Button>
		</HStack>
	);
}

export default ExitPreviewPanel;
