import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BlogCardsProps } from "../../classes/blog-cards";
import { RootState } from "../store";

const initialState = new BlogCardsProps();

export const blogCardsSlice = createSlice({
  name: "blogCardsSlice",
  initialState,
  reducers: {
    setBlogCards: (state, action: PayloadAction<any>) => {
      const temp:any = [];

      if (action.payload.post) {
        action.payload.post.blogCards.forEach((card) => {
          temp.push(card);
        });
      }

      return {
        ...state,
        blogCards: temp
      }
    },
    updatePageInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        pageInfo: action.payload
      }
    },
  },
});

// destructure actions and reducer from the slice (or you can access as counterSlice.actions)
const { actions, reducer } = blogCardsSlice;

// export individual action creator functions
export const { setBlogCards, updatePageInfo } = actions;

// Selectors can use the imported `RootState` type
export const selectBlogCards = (state: RootState) =>
  state.blogCardsSlice.blogCards;
export const selectBlogCardsLength = (state: RootState) =>
  state.blogCardsSlice.blogCards.length;
export const pageInfo = (state: RootState) =>
  state.blogCardsSlice.pageInfo;

export default reducer;
