export class Menu {
  menuData: {
      footerMenuLinks: {
          link: {
              title: string,
              url: string,   
          }
      },
      policies: { 
          policy: string,
      },
      follow: {
          socialLinks: {
              title: string,
              url: string,
          }
      },
      contactDetails: {
          email: string,
          phoneNumber: string,
      }
  }

  constructor () {
    this.menuData = {
      footerMenuLinks: {
          link: {
              title: "",
              url: "",
          }
      },
      policies: {
          policy: "",
      },
      follow: {
          socialLinks: {
              title: "",
              url: "",
          }
      },
      contactDetails: {
          email: "",
          phoneNumber: "",
      }
    }
  }
}