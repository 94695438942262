import { createSlice } from "@reduxjs/toolkit";
import { Menu } from "../../classes/menu";
import { RootState } from "../store";

const initialState = new Menu();

export const menuSlice = createSlice({
    name: "menuSlice",
    initialState,
    reducers: {
        setMenuData: (state, action) => {
            return {
                ...state,
                menuData: action.payload
            }
        }
    }
})

export const { setMenuData } = menuSlice.actions;

export const menuState = (state:RootState) => state.menuSlice;

export default menuSlice.reducer;