import { textStyles } from "./textStyles";

export const Input = {
  // style object for base or default style
  baseStyle: {
    field: {
      ...textStyles.heading10Medium,
    },
    addon: {
      ...textStyles.heading10Medium,
    },
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    custom: {
      field: { fontSize: "md", px: 4, h: 12, borderRadius: "base" },
      addon: { fontSize: "md", px: 4, h: 12, borderRadius: "base" },
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    bulb: {
      field: {
        border: "2px solid",
        borderColor: "obsidian.100",
        background: "none !important",
        _hover: {
          borderColor: "obsidian.200",
        },
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },
        _disabled: {
          opacity: 0.4,
          cursor: "not-allowed",
          backgroundColor: "obsidian.50",
        },
        _invalid: {
          borderColor: "coral",
          boxShadow: "none",
        },
        _focus: {
          zIndex: 1,
          borderColor: "abyss.500",
          boxShadow: `0 0 0 2px rgba(35, 204, 193, 0.05), 0 0 4px 0 #33a0ff`,
        },
      },
      addon: {
        border: "2px solid",
        borderColor: "obsidian.100",
        bg: "obsidian.50",
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {
    size: "custom",
    variant: "bulb",
  },
};
