import { configureStore} from "@reduxjs/toolkit";
//Slices:
import { filterSlice } from "./slices/filtersSlice";
import { blogCardsSlice } from "./slices/blogCardsSlice";
import {menuSlice} from "./slices/menuSlice";
menuSlice

export const store = configureStore({
  reducer: {
    filterSlice: filterSlice.reducer,
    blogCardsSlice: blogCardsSlice.reducer,
    menuSlice: menuSlice.reducer
  },
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
