import { fonts } from "./fonts";

const buttonDefaults = {
  display: "inline-flex",
  alignItems: "center",
  borderRadius: "base",
  fontFamily: fonts.DMSansMedium,
  fontWeight: 700,
  lineHeight: "1.5em",
  letterSpacing: -0.16,

  verticalAlign: "middle",
};
export const Link = {
  // 1. Update the base styles
  baseStyle: { color: "abyss.800" },
  sizes: {
    md: {
      h: "3em",
      fontSize: "md",
      px: "22px",
      py: "12px",
    },
  },
  defaultProps: {
    // variant: "solid",
    // size: "md",
    // colorScheme: "capri",
  },
  // 3.Add visual variants
  variants: {
    icon: {
      w: "3em",
      px: "12px",
      bg: "lunar.50",
      color: "abyss.800",
      alignitems: "center",
      justifyContent: "center",
      _hover: {
        bg: "lunar.100",
        textDecoration: "none",
      },
      _focus: {
        bg: "lunar.200",
      },
      _active: {
        bg: "lunar.200",
      },
      _disabled: {
        color: "granite.300",
      },
      ...buttonDefaults,
    },
    solid: (props) => ({
      color: "abyss.800",
      bg:
        props.colorScheme === "gray" ? "gray.100" : `${props.colorScheme}.500`,
      _hover: {
        textDecoration: "none",
        bg:
          props.colorScheme === "gray"
            ? "gray.100"
            : `${props.colorScheme}.400`,
      },
      _active: {
        bg:
          props.colorScheme === "gray"
            ? "gray.100"
            : `${props.colorScheme}.600`,
      },
      _focus: {
        bg:
          props.colorScheme === "gray"
            ? "gray.100"
            : `${props.colorScheme}.600`,
      },
      _disabled: {
        color: "granite.300",
      },
      ...buttonDefaults,
    }),
    outline: (props) => {
      const { colorScheme: c } = props;
      const borderColor = `gray.200`;
      return {
        border: "2px solid",
        borderColor: c === "gray" ? borderColor : `${c}.500`,
        color: "abyss.800",
        bg: "transparent",
        _hover: {
          bg: "transparent",
          textDecoration: "none",
          borderColor: c === "gray" ? "gray.100" : `${c}.300`,
        },
        _active: {
          bg: "transparent",
          borderColor: c === "gray" ? "gray.100" : `${c}.600`,
        },
        _disabled: {
          color: "granite.300",
          borderColor: c === "gray" ? "gray.100" : `${c}.300`,
        },
        _focus: {
          borderColor: c === "gray" ? "gray.100" : `${c}.600`,
        },
        ...buttonDefaults,
      };
    },
    link: (props) => {
      const { colorScheme: c } = props;
      return {
        color: c === "abyss" ? "abyss.800" : `${c}.500`,
        h: "auto",
        _hover: {
          textDecoration: "underline",
          _disabled: {
            textDecoration: "none",
          },
        },
        _active: {
          color: c === "abyss" ? "abyss.800" : `${c}.500`,
        },
        _disabled: {
          color: "granite.300",
        },
        ...buttonDefaults,
        px: "0",
        py: "0",
      };
    },
    primary: {
      bg: "capri.500",
      _hover: { textDecoration: "none", bg: "capri.400" },
      _disabled: {
        bg: "capri.300",
      },
      _focus: {
        bg: "capri.600",
      },
      _active: {
        bg: "capri.600",
      },
      ...buttonDefaults,
    },
    secondary: {
      border: "2px solid",
      borderColor: "capri.500",

      _hover: { textDecoration: "none", borderColor: "capri.400" },
      _disabled: {
        borderColor: "capri.300",
        color: "granite.300",
      },
      _focus: {
        borderColor: "capri.600",
      },
      _active: {
        borderColor: "capri.600",
      },
      ...buttonDefaults,
    },
    tertiary: {
      bg: "lunar.50",
      _hover: { textDecoration: "none", bg: "lunar.100" },
      _focus: {
        bg: "lunar.200",
      },
      _active: {
        bg: "lunar.200",
      },
      _disabled: {
        color: "granite.300",
      },
      ...buttonDefaults,
    },
    textButton: {
      bg: "transparent",
      _hover: { textDecoration: "none", bg: "transparent" },
      _focus: {
        bg: "transparent",
      },
      _disabled: {
        color: "granite.300",
      },
      ...buttonDefaults,
    },
  },
};
